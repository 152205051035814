import { Pipe, PipeTransform } from "@angular/core";

export interface DeliveryLineDto {
  lineId: string;
  orderId: string;
  customerId: string;
  deliveryId: string;
  poNumber: string;
  itemId: string;
  description: string;
  serial: string;
  quantity: number;
  delivered: number;
  available: number;
  serialnumbers?: string[],
  optionalSerialNumbers: boolean;// allows controlling visibility of serials

  packs?: number;
  packSize?: number;
  packCount?: number; // total packs - back calculated baed on stock transactions and serial numbers

}

@Pipe({
  name: "cleanLineId"
})
export class CleanLineIdPipe implements PipeTransform {
  transform(value: string, format?: string): string {
    const id = value.indexOf("-");
    return id > 0 ? value.slice(0, id) : value;
    }
}
